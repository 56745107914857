






























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { required, email } from 'vuelidate/lib/validators';
import { userService, authApi } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import { hsForm } from '@/components';
import MButton from '@/shared/components/MButton.vue';
import { MyTeamUser } from '@/types/myteam';

const AuthModule = namespace('auth');

@Component({
  components: {
    MButton,
    hsForm,
  },
  validations: {
    form: {
      firstName: { required },
      email: { required, email },
      role: { required },
    },
  },
})
export default class ModalInvite extends Vue {
  @Prop({ required: true }) modalId!: string;
  @Prop({ required: true }) editUser!: boolean;
  @Prop({ required: true }) user!: MyTeamUser;
  @Prop({ required: true }) users!: MyTeamUser[];

  @AuthModule.State roles!: any[];

  avatar: string | null = 'bot1';
  isLoading: boolean = false;
  hasError: boolean = true;
  error422: boolean = false;
  emailAlreadyInvited: boolean = false;
  formattedRoles: any[] = [];

  form: any = {
    firstName: '',
    email: '',
    role: '',
  };

  async mounted() {
    this.closeModalOnHide();
    this.formatRoles();

    if (this.editUser) {
      this.checkAvatarUrl();
      this.form = {
        firstName: this.user.name,
        email: this.user.email,
        role: this.user.role,
      };
    }
  }

  formatRoles() {
    this.formattedRoles = this.roles.map(role => ({ id: role.id, name: role.name })).filter(role => role.name !== 'student');
  }

  closeModalOnHide() {
    this.$root.$on('bv::modal::hide', (_: any) => {
      this.$emit('closeModal');
    });
  }

  checkAvatarUrl() {
    if (this.user.cover_image_url.search('bot') > 0) {
      const avatarUrl: string = this.user.cover_image_url.split('/').pop()!;
      this.avatar = avatarUrl.split('-').shift()!;
    } else {
      this.avatar = null;
    }
  }

  chooseAvatar(avatar: string) {
    this.avatar = avatar;
  }

  checkIfEmailIsAlreadyInvited() {
    const usedEmail: MyTeamUser = this.users.find(user => user.email === this.form.email)!;
    if (usedEmail?.name) {
      this.hasError = true;
      this.emailAlreadyInvited = true;
      this.form.firstName = usedEmail.name;
    } else {
      this.hasError = false;
      this.emailAlreadyInvited = false;
    }
  }

  async onSubmit() {
    try {
      this.isLoading = true;
      this.editUser ? await this.onEditUser() : await this.onInvite();
      this.$emit('closeModal', { reloadUsers: true });
    } catch (error) {
      if (error && error.response.status === 422) {
        this.hasError = true;
        this.error422 = true;
        return;
      }
      ToastHelper.dangerMessage(this.$t('my-account.views.my-team.messages.toast-error-default'));
    } finally {
      this.isLoading = false;
    }
  }

  async onInvite() {
    const { firstName, email, role } = this.form;
    const avatarImg = `https://static-cdn.myedools.com/avatar/${this.avatar}-circle.svg`;

    await authApi.createInvite(firstName, '', email, role.id, avatarImg);

    ToastHelper.successMessage(this.$t('my-account.views.my-team.messages.toast-success', { user: firstName }));
  }

  async onEditUser() {
    const { firstName, role } = this.form;
    const avatarImg =
      this.avatar !== null
        ? `https://static-cdn.myedools.com/avatar/${this.avatar}-circle.svg`
        : this.user.cover_image_url;

    await userService.update({
      id: this.user.id,
      first_name: firstName,
      cover_image_url: avatarImg,
      role_ids: [role.id],
    });

    ToastHelper.successMessage(this.$t('my-account.views.my-team.messages.toast-success-edit'));
  }

  @Watch('form.email', { deep: true })
  onChangeFormEmail(newEmail) {
    this.form.email = newEmail.toLowerCase();
  }
}
